import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BackgroundImg from '../../components/landing/BackgroundImg';
import Logo from "../../assets/images/logo.png";

import { resetUserPassword } from '../../redux/store/actions/userActions';

import { useTranslation, withTranslation, Trans } from "react-i18next";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const userDetails = useSelector((state) => state.user);

  const handleResetPassword = (e) => {
    e.preventDefault();

    // Added second password check
    if (password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    let data = {};
    data.password = password;
    data.token = token;

    dispatch(resetUserPassword(data))
      .then(() => {
        history.push("/reset-password-complete");
      })
      .catch((error) => {
        if (error?.error) {
          alert(
            "Reset password fallito. La password deve contenere almeno 8 caratteri e almeno un numero."
          );
        } else {
          alert("An error occurred while resetting the password.");
        }
      });
  };

  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <BackgroundImg />

      <Row
        style={{ position: "absolute", left: 0, margin: 0, width: "100%" }}
        className=" white-sq-container ps-5 pe-5 ps-md-0 pe-md-0"
      >
        <Col xl={4} md={6} className="mx-auto bg-white pt-2">
          <Col className="ps-1 ps-md-5 pe-1 pe-md-5 pt-2 pt-md-4 pb-2 pb-md-4">
            <Row>
              <Col className="text-center ps-5 pe-5">
                <img src={Logo} alt="Logo" className="mw-100" />
              </Col>
            </Row>
            <form onSubmit={handleResetPassword}>
              <Row className="mt-4">
                <Col>
                  <p>
                    La password deve essere di almeno 8 caratteri e contentere
                    sia lettere che numeri.
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <label htmlFor="password" className="mb-2">
                  {t("New password")}
                </label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control form-control-login mb-2"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label htmlFor="confirmPassword" className="mb-2">
                  {t("Confirm new password")}
                </label>
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className="form-control form-control-login"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Row>

              <Row className="mt-5 mb-4">
                <Button
                  type="submit"
                  color="primary"
                  disabled={password !== confirmPassword}
                >
                  {t("Save new password")}
                </Button>
              </Row>
            </form>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
